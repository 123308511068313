/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";

import {State} from "../../../../../../redux/reducers";

import {isEmpty} from "../../../../../../utils/validation";

import "@reach/menu-button/styles.css";
import "./styles/index.css";

import PanelDataLoadingComponent from "./component/PanelDataLoadingComponent";
import EventEmitter from "../../../../../../common/EventEmitter";

interface Props {
    layoutName: string;
}

const PanelDataLayoutComponent: React.FC<Props> = (props) => {
    const { layoutName } = props;

    const [isInLoading, setIsInLoading] = useState<boolean>(false);

    const panelItemSelector = useSelector((state: State) => state.panelItem);

    useEffect(() => {
        // Action button submitting
        if (
            ((panelItemSelector.isLoadingSubmitActionData && isEmpty(panelItemSelector.loadingData.isRequestFromDialog)) ||
            panelItemSelector.isLoadingGetTableData) &&
            panelItemSelector.loadingData.targetPanel === layoutName
        ) { 
            setIsInLoading(true);
        }

        if (panelItemSelector.isLoadingSubmitActionData && panelItemSelector.loadingData.isRequestFromDialog &&
            panelItemSelector.loadingData.targetPanel === layoutName
        ) {
            EventEmitter.emit('DIALOG_ACTION', {
                isSubmitting: true
            })
        }
        if (
            (
                panelItemSelector.isSuccessSubmitActionData ||
                panelItemSelector.isErrorSubmitActionData 
            ) &&
            (
                !isEmpty(panelItemSelector.successData) ?
                    panelItemSelector.successData.targetPanel === layoutName :
                    panelItemSelector.errorData.targetPanel === layoutName
            )
        ) {
            EventEmitter.emit('DIALOG_ACTION', {
                isSubmitting: false,
                panelItemSelector
            })
        }
        if (
            (
                panelItemSelector.isSuccessSubmitActionData ||
                panelItemSelector.isErrorSubmitActionData ||
                panelItemSelector.isSuccessGetTableData ||
                panelItemSelector.isErrorGetTableData
            ) &&
            (
                !isEmpty(panelItemSelector.successData) ?
                    panelItemSelector.successData.targetPanel === layoutName :
                    panelItemSelector.errorData.targetPanel === layoutName
            )
        ) {
            setIsInLoading(false);
        }
        // eslint-disable-next-line
    }, [panelItemSelector, EventEmitter]);

    return(
        <div className={
            classNames(
                "panel-table",
                {
                    "loading": isInLoading
                }
            )
        }>
            <PanelDataLoadingComponent
                panelInLoading={layoutName}
                panelData={panelItemSelector}
                isTableInLoadingState={isInLoading}
                setIsTableInLoadingState={(loadingState: boolean)=>setIsInLoading(loadingState)}
            />
            <div
                className={
                    classNames(
                        "panel-table-dt-component",
                        {
                            "loading": isInLoading
                        }
                    )
                }
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default PanelDataLayoutComponent;
