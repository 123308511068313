/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar as SB } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';

import './styles/index.css';
import './styles/panel_menu.css';
import logo from '../../assets/company-cbi.png';

import CompanyLogo from './components/CompanyLogo';

import { getSidebarMenu, toggleSidebarMenu } from '../../redux/actions/sidebarItem';
import { State } from '../../redux/reducers';

import { isEmpty, isNull } from '../../utils/validation';
import styled from 'styled-components';
import __t from '../../utils/translation';

const PanelMenuItem = styled(PanelMenu)`
.p-menuitem {
    background: ${!isEmpty(process.env.REACT_APP_COLOR_SIDEBAR) ? process.env.REACT_APP_COLOR_SIDEBAR + " !important" : ""};
}

.p-menuitem-text {
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

.p-panelmenu-header > a .p-panelmenu-icon {
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

#sidebar {
    background: ${!isEmpty(process.env.REACT_APP_COLOR_SIDEBAR) ? process.env.REACT_APP_COLOR_SIDEBAR + " !important" : ""};
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

width: 100%;
height: calc(100vh - 75px);
overflow-y: auto;
`

interface MenuItem {
    _id: string;
    label: string;
    menuRoute: string;
    menuAction: string;
    order: number;
    category: string;
    children?: MenuItem[];
    Locale?: { [key: string]: { label: string } };
}

const sidebarArrayToDataTree = (list: any, isRoot=false) => {
    let parsedList: any = [];
    if (isRoot) {
        list = JSON.stringify(list);
        list = list.split("children").join("items");
        list = JSON.parse(list);
    }    
    if (Array.isArray(list)) {
        list.forEach((data: any) => {
            if (!isEmpty(data)) {
                let command = "";
                if (data.menuRoute) command = `window.location.hash="${data.menuRoute}";`;
                if (data.menuAction) command += data.menuAction;
                data.label = __t(data, "label");
                // eslint-disable-next-line no-new-func
                data.command = new Function (command);
                if (data.items) sidebarArrayToDataTree(data.items);
                parsedList.push(data);
            }
        });
    }
    return parsedList;
};

const Sidebar: React.FC = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sidebarMenu, setSidebarMenu] = useState<any>(null);
    const toastRef = useRef<Toast>(null);

    const sidebarRef = useRef(null);

    const dispatch = useDispatch();
    const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);

    const parseMenu = (menuData: any) => {
        // Function to sort menu items by order
        const sortByOrder = (a: MenuItem, b: MenuItem) => a.order - b.order;

        // Sort the menu items
        menuData.sort(sortByOrder);

        // Add your custom logic to move "Sign Out" to the bottom
        const signOutItemIndex = menuData.findIndex((item: MenuItem) => item.label === "Sign Out");
        if (signOutItemIndex !== -1) {
            const signOutItem = menuData.splice(signOutItemIndex, 1)[0];
            menuData.push(signOutItem);
        }
    }

    useEffect(() => {
        if (!isEmpty(sidebarItemSelector) && !isNull(sidebarRef.current)) {
            if(!isEmpty(sidebarItemSelector.isSidebarVisible) && !isNull(sidebarRef.current)) {
                sidebarItemSelector.isSidebarVisible? setIsSidebarVisible(true):setIsSidebarVisible(false);
            }

            if (sidebarItemSelector.isLoadingGetSidebarData && !isNull(sidebarRef.current)) {
                setIsLoading(true);
            }
            if (sidebarItemSelector.isSuccessGetSidebarData && !isNull(sidebarRef.current)) {
                parseMenu(sidebarItemSelector.successData.data || []);
                setIsLoading(false);
                setSidebarMenu(sidebarArrayToDataTree(sidebarItemSelector.successData.data || [], true));
            }

            let returnType = 0;

            if (sidebarItemSelector.isErrorGetSidebarData && !isNull(sidebarRef.current)) {
                setIsLoading(false);
                let toastOptions: any = {summary: "Failed to fetch data", icon: <></>, severity: "error", detail: ""};

                if (!isEmpty(sidebarItemSelector?.errorData)){
                    if (typeof sidebarItemSelector.errorData?.message === "string") {
                        returnType = 4;
                        toastOptions.detail = sidebarItemSelector.errorData.message;
                    } else if (typeof sidebarItemSelector.errorData?.message === "object"){
                        returnType = sidebarItemSelector.errorData.message?.ReturnType || 2;
                        toastOptions.detail = sidebarItemSelector.errorData.message?.ReturnMessage;
                        if (sidebarItemSelector.errorData.message?.ReturnType === 2) {
                            toastOptions.severity = "warn";
                        }
                    }
                }

                toastOptions.severity === "warn"? toastOptions["life"] = 15000:toastOptions["life"] = 30000;

                if (!isEmpty(toastOptions?.detail)) {
                    if (toastOptions.detail === "jwt expired") {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.reload();
                    }
                    if (toastRef && parseInt(`${returnType}`) > 0) {
                        toastRef.current!.show(toastOptions);
                    } else {
                        console.error(toastOptions.detail);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [sidebarItemSelector]);

    useEffect(() => {
        setTimeout(() => { // Delays dispatch so navbar can dispatch toggle first
            if (!isNull(sidebarRef.current)) {
                getSidebarMenu(dispatch);
            }
        }, 150);

        return () => {
            sidebarRef.current = null;
        }
        // eslint-disable-next-line
    }, []);

    const titleBackround = !isEmpty(process.env.REACT_APP_COLOR_TITLE_SIDEBAR) ? {background: process.env.REACT_APP_COLOR_TITLE_SIDEBAR} : {};

    return (
        <div ref={sidebarRef} style={{display: isSidebarVisible? "block":"none"}}>
            <Toast ref={toastRef}/>
            <SB visible={isSidebarVisible}
                onHide={() => toggleSidebarMenu(dispatch, false)}
                dismissable={false}
                closeOnEscape={false}
                modal={false}
                showCloseIcon={false}
                style={{overflowY: "hidden"}}
                id={'sidebar'}
            >

                <CompanyLogo companyLogo={logo}/>

                <div className="title" style={titleBackround}>
                    <p style={{fontWeight: "bold"}}>{!isEmpty(process.env.REACT_APP_TITLE) ? process.env.REACT_APP_TITLE : "BI Tools Demo"}</p>
                </div>

                {
                    isLoading?
                        <div style={{
                            width: "100%",
                            height: "500px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <span style={{color: "#fff"}}>Loading menu...</span>
                        </div>:
                        <PanelMenuItem
                            model={sidebarMenu}
                        />
                }
            </SB>
        </div>
    )
};

export default Sidebar;
