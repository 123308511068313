/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import moment, { Moment } from "moment";

export function getTodayDate(value: string): string {
    if (typeof value === 'object') return ""
    
    let generatedDate = (value || '').replace(/\s/g,'');

    if (generatedDate === "TODAY_DATE") {
        generatedDate = moment().format("YYYY-MM-DD");
    } else if (generatedDate === "TODAY_DATETIME") {
        generatedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    } else if (generatedDate.includes("TODAY_DATE")) {
        generatedDate = generatedDate.replace("TODAY_DATE", "");

        if (generatedDate[0] === "+" || generatedDate[0] === "-") {
            let operator = generatedDate[0];
            generatedDate = generatedDate.replace(operator, "");
            try {
                let dateIncrementValue: number = parseInt(generatedDate);
                let date: Moment = moment();

                if (operator === "+") {
                    date = date.add(dateIncrementValue, 'days')
                }

                if (operator === "-") {
                    date = date.subtract(dateIncrementValue, 'days')
                }

                generatedDate = date.format("YYYY-MM-DD");
            } catch (error) {
                console.error("Failed to parse Date value", error);
            }
        }
    }  else if (generatedDate.includes("TODAY_DATETIME")) {
        generatedDate = generatedDate.replace("TODAY_DATETIME", "");

        if (generatedDate[0] === "+" || generatedDate[0] === "-") {
            let operator = generatedDate[0];
            generatedDate = generatedDate.replace(operator, "");
            try {
                let dateIncrementValue: number = parseInt(generatedDate);
                let date: Moment = moment();

                if (operator === "+") {
                    date = date.add(dateIncrementValue, 'days')
                }

                if (operator === "-") {
                    date = date.subtract(dateIncrementValue, 'days')
                }

                generatedDate = date.format("YYYY-MM-DD HH:mm:ss");
            } catch (error) {
                console.error("Failed to parse Date value", error);
            }
        }
    } else if (generatedDate === "END_OF_DATE") {
        generatedDate = moment(new Date("2049-12-31 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
    } else {
        return value;
    }

    return generatedDate;
}

export function isStringUTC(valueToCheck: string) {
    return /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(valueToCheck);
}