/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';

import PanelTableComponent from '..';
// import { TabPanel, TabView } from '../../../../../../../components/Tab';
import { TabView, TabPanel } from 'primereact/tabview';

import { ActionData } from '../../../../../../../models/classes/ActionData';
import { PanelData } from '../../../../../../../models/classes/PanelData';
import { PanelFieldData } from '../../../../../../../models/classes/PanelFieldData';

import { IConsumeAPI } from '../../../../../../../models/interfaces/IConsumeAPI';
import { IFieldValue } from '../../../../../../../models/interfaces/IFieldValue';
import { IPostAction } from '../../../../../../../models/interfaces/IPostAction';

import { consumeAPI } from '../../../../../../../redux/actions/panelItem';

import { getTodayDate } from '../../../../../../../utils/date';
import { isEmpty } from '../../../../../../../utils/validation';
import { checkPermission } from '../../../../../../../utils/permission';
import Field, { IFieldProps } from '../../../../../../../components/Field';
import __t from '../../../../../../../utils/translation';

interface ExpandedRowsValue {
    tabName: string;
    rowName: string;
    rowValues: IFieldValue[];
}

interface ActiveTabIndex {
    rowName: string;
    index: number;
}

const TableRowExpansionElement = (
    rowData: any,
    targetPanel: string,
    workspaceName: string,
    widgetName: string,
    panelData: PanelData,
    actionButtons: ActionData[],

    activeTabIndexes: ActiveTabIndex[],
    setActiveTabIndexes: Function,

    dummyState: boolean,
    triggerReRender: Function, // expandedRowsValue and activeTabIndexes values are updated after manually triggers re-render by changing another state's value

    expandedRowsValue: ExpandedRowsValue[],
    setExpandedRowsValue: Function,

    isBatchActionButtonExist: boolean,

    dispatch: Function,
    form: any
): JSX.Element => {
    const updateValue = (rowsValueIndex: number, fieldValues: IFieldValue[]) => {
        let newExpandedRowsValue: ExpandedRowsValue[] = expandedRowsValue;
        newExpandedRowsValue[rowsValueIndex].rowValues = fieldValues;
        setExpandedRowsValue(newExpandedRowsValue);
        triggerReRender(!dummyState);
    };

    const renderFields = (fieldIndex: number, field: PanelFieldData, rowsValueIndex: number): JSX.Element => {
        if (field) {
            // #region Auto add values to expanded row fields
            let defaultValue = "";

            expandedRowsValue[rowsValueIndex]?.rowValues.forEach((rowValue: IFieldValue) => {
                if (rowValue.fieldName in rowData && rowValue.fieldName === field.FieldName) {
                    defaultValue = rowData[rowValue.fieldName];
                }
            });

            if (isEmpty(defaultValue)) {
                if (field.DataType === "Date" && !isEmpty(field.DefaultValue)) {
                    defaultValue = getTodayDate(field.DefaultValue);
                }

                if (field.DataType === "Datetime" && !isEmpty(field.DefaultValue)) {
                    defaultValue = getTodayDate(field.DefaultValue);
                }
            }
            // #endregion

            let fieldId = rowsValueIndex;
            let fieldRef: any = React.createRef();
            let fieldValues = expandedRowsValue[rowsValueIndex]?.rowValues;
            let setFieldValues = (fieldValues: IFieldValue[]) => updateValue(rowsValueIndex, fieldValues);
            const container = {
                panelName: panelData.PanelName,
                widgetName,
                form
            }

            let customProps = {
                defaultValue,
                container,
                style: { height: "100%" },
                panelStyle: {
                    height: "100%",
                    top: "33px",
                    overflow: "auto"
                }
            };

            let props: IFieldProps = {
                fieldId, fieldRef, fieldIndex, field, fieldValues, setFieldValues, customProps, container
            }

            return (
                <div
                    ref={fieldRef}
                    style={{
                        height: "100%",
                        whiteSpace: "nowrap",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <label>{__t(field, "FieldLabel")}</label>
                    <Field {...props} />
                </div>
            );
        }
        return <div />;
    };

    const renderColumns = (actionButton: ActionData, rowsValueIndex: number, yIndex: number): JSX.Element[] => {
        return [...Array(actionButton.NumberOfColumns)].map((x, xIndex) => {
            const currentDisplayPosition = `${xIndex},${yIndex}`;
            const fieldIndex: number = actionButton.Fields
                .findIndex(field => field.DisplayPosition === currentDisplayPosition);
            const field: PanelFieldData = actionButton.Fields[fieldIndex];

            return (
                <div key={currentDisplayPosition} className="p-col">
                    {renderFields(fieldIndex, field, rowsValueIndex)}
                </div>
            );
        })
    };

    const renderGrids = (actionButton: ActionData, rowsValueIndex: number): JSX.Element[] => {
        return (
            [...Array(actionButton.NumberOfRows)].map((y, yIndex) => (
                <div
                    key={yIndex}
                    className="p-grid p-justify-between"
                    style={{ width: actionButton.ActionPanelWidth }}
                >
                    {renderColumns(actionButton, rowsValueIndex, yIndex)}
                </div>
            ))
        )
    };

    const renderTabPanels = (actionsData: ActionData[]): JSX.Element[] => {
        const panelFields = panelData.Fields;
        actionsData = actionsData.filter((actionData) => actionData.ShowOnExpandedTab && !checkPermission(actionData));

        return actionsData.map((actionData, actionButtonIndex) => {
            actionData.Hidden = checkPermission(actionData)
            const rowsValueIndex: number = expandedRowsValue
                .findIndex(obj => obj.tabName === actionData.ActionName &&
                    obj.rowName === rowData["RowNumber"].toString());

            let tableAPIParameters: PanelFieldData[] = [];

            if (actionData.ActionType === "ShowTable") {
                if (!isEmpty(actionData.LoadData.APIParameters) && actionData.LoadData.APIParameters) {
                    actionData.LoadData.APIParameters.forEach((field) => {
                        expandedRowsValue[rowsValueIndex]?.rowValues.forEach((rowValue: IFieldValue) => {
                            if (rowValue.fieldName in rowData && rowValue.fieldName === field.FieldName) {
                                tableAPIParameters.push({
                                    FieldName: field.FieldName,
                                    DataType: field.DataType,
                                    Value: field.Value ? field.Value : rowData[rowValue.fieldName]
                                } as PanelFieldData);
                            }
                        });
                    });
                } else {
                    actionData.Fields.forEach((field) => {
                        expandedRowsValue[rowsValueIndex]?.rowValues.forEach((rowValue: IFieldValue) => {
                            if (rowValue.fieldName in rowData && rowValue.fieldName === field.FieldName) {
                                tableAPIParameters.push({
                                    FieldName: field.FieldName,
                                    DataType: field.DataType,
                                    Value: rowData[rowValue.fieldName]
                                } as PanelFieldData);
                            }
                        });
                    });
                }
            }

            return (
                <TabPanel key={actionButtonIndex} header={__t(actionData, "ActionLabel")}>
                    {
                        actionData.ActionType === "ShowTable"?
                            <PanelTableComponent
                                {...{
                                    workspaceName,
                                    widgetName,
                                    showLoadingText: true,
                                    hideGlobalSearch: actionData.TableOption?.HideGlobalSearch,
                                    hideSectionBreak: true,
                                    panelData: {
                                        ...panelData,
                                        ...{
                                            PanelName: `${panelData.PanelName}_${actionData.ActionName}_${rowsValueIndex}`,
                                            Fields: actionData.Fields,
                                            LoadData: {
                                                APIName: actionData.LoadData.APIName? actionData.LoadData.APIName: actionData.APIName,
                                                APIParameters: tableAPIParameters
                                            }
                                        }
                                    }
                                }}
                            />:
                            <>
                                {renderGrids(actionData, rowsValueIndex)}
                                {actionData.Hidden ? '' : <Button
                                    label="Submit"
                                    onClick={() => {
                                        if (actionData.OnClickAction === "CallAPI" || actionData.OnClickAction === "GenerateReport") {
                                            let submitData: IFieldValue[] = [];

                                            if (panelFields)
                                                if (panelFields.length > 0)
                                                    panelFields.forEach((field: PanelFieldData) => {
                                                        if (rowData.hasOwnProperty(field.FieldName)) {
                                                            let value = rowData[field.FieldName];

                                                            if (field.DataType === "Date") {
                                                                value = moment(rowData[field.FieldName], field.FieldFormat).format("YYYY-MM-DD");
                                                            }

                                                            submitData.push({
                                                                fieldName: field.FieldName,
                                                                fieldType: field.DataType,
                                                                value,
                                                                dataType: field.DataType,
                                                            });
                                                        }
                                                    });

                                            if (expandedRowsValue[rowsValueIndex]?.rowValues) {
                                                expandedRowsValue[rowsValueIndex]?.rowValues.forEach((rowValue: IFieldValue) => {
                                                    const overrideFieldIndex = submitData.findIndex(obj => obj.fieldName === rowValue.fieldName);
                                                    overrideFieldIndex > -1?
                                                        submitData[overrideFieldIndex].value = rowValue.value:
                                                        submitData.push(rowValue);
                                                });
                                            }

                                            actionData?.PostActions?.forEach((postAction: IPostAction, idx: number) => {
                                                if (postAction?.RefreshField) {
                                                    if(actionData.PostActions) {
                                                        actionData.PostActions[idx]["RefreshFieldRowInfo"] = rowData;
                                                    }
                                                }
                                            });

                                            if (rowData.hasOwnProperty("_id")) {
                                                submitData.push({
                                                    fieldName: "_id",
                                                    fieldType: "InputText",
                                                    value: rowData._id,
                                                    dataType: "Text",
                                                });
                                            }

                                            const actionOptions: IConsumeAPI = {
                                                targetPanel,
                                                APIName: actionData.APIName,
                                                inputParameterValues: submitData,
                                                UseQueue: false,
                                                postActions: actionData.PostActions
                                            };

                                            consumeAPI(
                                                dispatch,
                                                actionOptions
                                            );
                                        }
                                    }}
                                />}
                            </>
                    }
                </TabPanel>
            );
        });
    };

    const tableName = `${widgetName}_${panelData.PanelName}`;
    const expansionTabs: any = document.getElementsByClassName("expansion-tab-" + tableName);

    let colSpan = (panelData.Fields.length - 1) + // minus RowNumber
        1 +  // expander button
        1; // action button

    if (isBatchActionButtonExist) {
        colSpan++
    }

    for (const expansionTab of expansionTabs) {
        if (expansionTab?.parentElement?.colSpan) {
            expansionTab.parentElement.colSpan = colSpan;
        }
    }

    return (
        <TabView className={"expansion-tab-" + tableName} style={{width:'100%'}}>
            {renderTabPanels(actionButtons)}
        </TabView>
    );
};

export default TableRowExpansionElement;
