/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnProps } from 'primereact/column';

import { PanelFieldData } from '../../../../../../../models/classes/PanelFieldData';
import { Tooltip } from 'primereact/tooltip';
import { isEmpty } from '../../../../../../../utils/validation';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { classNames } from "primereact/utils";
import __t from '../../../../../../../utils/translation';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Workspace from '../../../../../../Workspace';
import FileUpload from '../../../../../../../components/FileUpload';


const checkboxField = (rowData: any, column: any) => (
    <div
        style={{
            width: "100%",
            textAlign: "center"
        }}
    >
        <Checkbox
            value=""
            checked={
                rowData[column.field] === true ||
                rowData[column.field] === "true" ||
                rowData[column.field] === 1 || 
                rowData[column.field] === '1'
            }
        />
    </div>
);

const colorButton = (rowData: any, column: any) => (
    <div
        style={{
            width: "100%",
            textAlign: "center"
        }}
    >
        <div style={{
            display: "inline-block",
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            backgroundColor: `${rowData[column.field]}`,
            border: `3px solid ${rowData[column.field]}`
        }}></div>
    </div>
)

const dropdownField = (rowData: any, column: any, options: any[]) => {
    let dropdownOptions = [];
    if (options != null && options?.length > 0) {
        for (const listOfValues of options) {
            const values = Object.keys(listOfValues).filter(key => key !== "HPO_DEFAULT_VALUE").map(key => listOfValues[key]);
            dropdownOptions.push({
                LabelDescription: values.length > 1 ? values[1] : values[0],
                LabelValue: values[0]
            })
        }
    }
    return (
        <Dropdown
            value={rowData[column.field]}
            optionLabel="LabelDescription"
            optionValue="LabelValue"
            options={dropdownOptions}
            disabled
        />
    );
}

const saveState = (options: any, appData:any, setAppData:any, widgetKey:string) => {
    const data = appData[widgetKey]
    setAppData({
        ...appData,
        [widgetKey]: {
            ...data,
            [options.rowData.RowNumber]: true
        }
    })
}

const parseEditor = (column: PanelFieldData, options: any, appData:any, setAppData:any, widgetKey:string) => {
    switch (column.InputType) {
        case 'InputText':
            return (<InputText 
                disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                type="text" value={options.value} onChange={(e) => { saveState(options, appData, setAppData, widgetKey); options.editorCallback(e.target.value) }} />);
        case 'InputNumber':
            return (
                <InputNumber
                    value={options.value} onChange={(e) =>{ saveState(options, appData, setAppData, widgetKey); options.editorCallback(e.value) }}
                    {...(!isEmpty(column.InputNumberProps)) && { ...column.InputNumberProps }}
                    tooltip={isEmpty(column.Tooltip) ? undefined : column.Tooltip}
                    tooltipOptions={{
                        position: column.TooltipPosition || 'right'
                    }}
                    readOnly={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                    disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}                    
                    // className={classNames({
                    //     "p-invalid": true
                    // })}
                    />
            );
        case 'InputCheckbox':
            return (
                <div
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }}
                >
                    <Checkbox
                        onChange={(e) => {
                            saveState(options, appData, setAppData, widgetKey);
                            options.editorCallback(e.checked);
                        }}
                        disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                        checked={options.value === true || options.value === 'true' || options.value === 1 || options.value === '1'}
                    />
                </div>
            );
        case "Calendar":
            const fieldFormat = (column.FieldFormat || "MM/DD/YYYY").toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
            let value = options.value;

            value = moment(value, fieldFormat).format("YYYY-MM-DD");
            return (
                <KeyboardDatePicker
                    key={`date-${column.FieldName}`}
                    //className={classes.dateTimePicker}
                    id={`Calendar-${column.FieldName.toString().replace(",", "")}`}
                    name={column.FieldName}
                    value={options.value}
                    format={column.FieldFormat || "MM/DD/YYYY"}
                    onChange={(e) => { saveState(options, appData, setAppData, widgetKey); options.editorCallback(e?.toDate()); } }
                    showTodayButton
                    disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                    // className={classNames({
                    //     "p-invalid": true
                    // })}
                    />
            )
        case "Datetime":
            return (
                <KeyboardDateTimePicker
                    key={`dateTime-${column.FieldName}`}
                    id={`dateTime-${column.FieldName.toString().replace(",", "")}`}
                    //className={classes.dateTimePicker}
                    name={column.FieldName}
                    value={options.value}
                    format={column.FieldFormat || "MM/DD/YYYY hh:mm A"}
                    onChange={(e) => { saveState(options, appData, setAppData, widgetKey); options.editorCallback(e?.toDate()) }}
                    showTodayButton
                    disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                    // className={classNames({
                    //     "p-invalid": true
                    // })}
                    />
            )
        case "Dropdown":
            let showValue: boolean;
            let dropdownOptions = [];

            column?.DropdownOptions?.forEach((option: any) => option.ShowValue ? showValue = true : showValue = false);

            if (column?.ListOfValues?.length > 0) {
                for (const listOfValues of column.ListOfValues) {
                    const values = Object.keys(listOfValues).filter(key => key !== "HPO_DEFAULT_VALUE").map(key => listOfValues[key]);
                    dropdownOptions.push({
                        LabelDescription: values.length > 1 ? values[1] : values[0],
                        LabelValue: values[0],
                        Values: values
                    })
                }
            }

            const dropdownTemplate = (option: any) => {
                return (
                    <div>
                        {showValue ? (
                            <>
                                <span>{option.LabelValue}</span>
                                <br />
                            </>
                        ) : null}
                        {
                            column.DisplayAllField && option.Values ? option.Values.map((element: any, index: any) =>
                                index > 0 ? (<span> {element} </span>) : null
                            ) : <span>{option.LabelDescription}</span>
                        }
                    </div>
                );
            };

            const dropdownSelectedTemplate = (option: any) => {
                return (
                    <div>
                        {showValue && option ? (
                            <>
                                <span>{option.LabelValue}</span>
                                <br />
                            </>
                        ) : null}
                        <span>{option ? option.LabelDescription : ""}</span>
                    </div>
                );
            };

            return (
                <Dropdown
                    value={options.value}
                    optionLabel="LabelDescription"
                    optionValue="LabelValue"
                    options={dropdownOptions}
                    onChange={(e) => { saveState(options, appData, setAppData, widgetKey); options.editorCallback(e.value) }}
                    virtualScrollerOptions={{ itemSize: 38 }}
                    valueTemplate={dropdownSelectedTemplate}
                    itemTemplate={dropdownTemplate}
                    scrollHeight="100px"
                    showClear
                    filter
                    tooltip={isEmpty(column.Tooltip) ? undefined : column.Tooltip}
                    tooltipOptions={{
                        position: column.TooltipPosition || 'right'
                    }}
                    // className={classNames({
                    //     "p-invalid": true
                    // })}
                    disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                    />
            );
        
        case "InputLOV":
            
            const footerContent = appData.lovData ? (
                <div>
                    <Button label="Select" icon="pi pi-check" onClick={() => {
                        if (appData.lovData) {
                            let fieldValue = null;
                            for (const emitedData of appData.lovData) {
                                if (emitedData.DataSource === column.FieldName && column.InputType === "InputLOV") {
                                    fieldValue = emitedData.EmittedValue
                                    break;
                                }
                                if (!isEmpty(column.FieldConfiguration?.FieldValue) &&
                                    (emitedData.DataSource === column.FieldName && column.InputType === "InputLOV")) {
                                    fieldValue = emitedData.EmittedValue
                                    break;
                                }
                            }

                            for (let index = 0; index < Object.keys(options.rowData).length; index++) {
                                const element = Object.keys(options.rowData)[index];
                                if (element !== 'ID') {
                                    appData.lovData.forEach((lov:any) => {
                                        if (element === lov.DataSource) {
                                            options.rowData[element] = lov.EmittedValue
                                        }
                                    });
                                }
                            }
        
                            let data = appData;
                            if (isEmpty(data)) {
                                Object.assign(data, {
                                    [`${widgetKey}___LOV`]: [],
                                    selectedLovData: null
                                })
                            }

                            data.selectedLovData = {
                                FieldValue: fieldValue
                            }
                            
                            const index = data[`${widgetKey}___LOV`].findIndex((x: any) => {
                                return x && !isEmpty(x['LOV_' + column.FieldName])
                            })
        
                            if (index < 0) {
                                data[`${widgetKey}___LOV`].push({
                                    ['LOV_' + column.FieldName]: data.lovData
                                })
                            } else {
                                data[`${widgetKey}___LOV`][index] = {
                                    ['LOV_' + column.FieldName]: data.lovData
                                }
                            }
        
                            setAppData(data)

                        }
                        
                        
                        const data = appData;
                        data.ShowDialog = false;
                        setAppData(data);

                        options.value = appData.selectedLovData ? appData.selectedLovData.FieldValue : "";
                        saveState(options, appData, setAppData, widgetKey);
                        options.editorCallback(options.value)

                    }} autoFocus />
                </div>
            ) : undefined;
            return (
                <div className="" style={{display:'flex'}}>
                    <InputText
                        value={options.value}
                        readOnly={true}
                        // style={{ ...customProps?.style }}
                    />
                    <Button 
                    disabled={!isEmpty(options.rowData.controlFields) && options.rowData.controlFields.hasOwnProperty(options.field) ? options.rowData.controlFields[options.field] || undefined : false}
                    icon="pi pi-search" className="p-button-grey" type="button" 
                        onClick={() => 
                           {
                          
                            const data = appData;
                            data.lovData = null;
                            data.ShowDialog = true
                            setAppData(data)
                            saveState(options, appData, setAppData, widgetKey);
                            options.editorCallback(options.value)
                           }
                        } 
                    />
                    <Dialog
                        modal={true}
                        visible={appData.ShowDialog}
                        onHide={()=> {
                            const data = appData;
                            data.ShowDialog = false
                            setAppData(data)
                            options.editorCallback(options.value)
                        }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "100vw",
                            maxHeight: "90%",
                            width: isEmpty( column.FieldConfiguration?.DialogWidth) ? "60%" : column.FieldConfiguration?.DialogWidth,
                            overflow: "auto"
                        }}
                        showHeader={false}
                        footer={!isEmpty(appData.lovData) ? footerContent : undefined}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "40px",
                                position: "relative"
                            }}
                        >
                            <Button
                                icon="pi pi-times"
                                iconPos="right"
                                className="p-button-secondary"
                                label="Close"
                                style={{
                                    position: "absolute",
                                    right: 0
                                }}
                                onClick={()=> {
                                    const data = appData;
                                    data.ShowDialog = false
                                    setAppData(data)
                                    options.editorCallback(options.value )
                                }}
                            />
                        </div>
                        {appData.ShowDialog && <Workspace workspaceData={column.FieldConfiguration?.Workspace} />}
                    </Dialog>
                </div>
            )
        case "FileUpload":
            return (
                <FileUpload field={column} onFileChange={(file:File | null) => {                            
                        saveState(options, appData, setAppData, widgetKey); 
                        options.editorCallback(file)
                }}
                ></FileUpload>
            )
        default:
            return null;
    }
}

const TableColumnElement = (fields: PanelFieldData[], editable: boolean = false, appData:any, setAppData:any, widgetKey:string): JSX.Element[] => {
        
    const specialFieldTypes: any = {
        "InputCheckbox": checkboxField,
        "ColorRadioButton": colorButton,
        "Dropdown" : dropdownField
    };

 

    const getCellStyle = (rowData: any, field: any) => {
        const textColorKey = `${field}_HPO_TEXT_COLOR`;
        const cellColorKey = `${field}_HPO_CELL_COLOR`;
        
        const rowTextColorKey = 'HPO_ROW_TEXT_COLOR';
        const rowBackgroundColorKey = 'HPO_ROW_BACKGROUND_COLOR';
        
        const style: React.CSSProperties = {};
    
        if (!rowData[rowTextColorKey] && rowData[textColorKey]) {
            style.color = rowData[textColorKey];
        }
        
        if (!rowData[rowBackgroundColorKey] && rowData[cellColorKey]) {
            style.backgroundColor = rowData[cellColorKey];
        }
        
        return style;
    };

    return fields.map((column: PanelFieldData, index: number) => {
        const tooltip = !isEmpty(column.Tooltip) ? <Tooltip target={`#${column.FieldName}`} position={column.TooltipPosition || 'right'} content={column.Tooltip} /> : null;
        const field = specialFieldTypes.hasOwnProperty(column.InputType) ?
                      specialFieldTypes[column.InputType] : undefined
 
        const parseField  = (rowData: any, columnTable: any) => {
            if (column.InputType === "Dropdown") {
                return field(rowData, columnTable, column.ListOfValues)
            } else {
                return field(rowData, columnTable)
            }
        }

        const parseBody = (rowData: any, columnTable: any) => {
            const style = getCellStyle(rowData, column.FieldName);
            return <div style={style}>{rowData[column.FieldName]}</div>;
        }
        
        return (
            <Column
                style={{
                    display: column?.Hidden ? "none" : "table-cell",
                    minWidth: column.FieldName === "RowNumber" ? "0px" : column.FieldWidth,
                    // maxWidth: column.FieldName === "RowNumber" ? "0px" : column.FieldWidth,
                }}
                body={specialFieldTypes.hasOwnProperty(column.InputType) ?
                    parseField : parseBody}
                sortable={true}
                key={index}
                field={column.FieldName}
                filter={column.FieldName !== "RowNumber" && column.FilterType !== "" && column.FilterType !== undefined && !column.HideFilter}
                filterMatchMode="contains"
                // filterElement={generateFilter(column.FieldName, column.FilterType, column.FilterItems)}
                header={<>{tooltip}<span id={column.FieldName}>{__t(column, "FieldLabel") || column.FieldName}</span></>}
                // cellEditValidator={(e) => {
                //     console.log("EEE", e);
                    
                //     return false;
                // }}
                {
                ...(
                    (
                        editable && column?.Editable && column.InputType !== null && column.InputType !== "ColorRadioButton"
                    ) &&
                    { editor: (options) => parseEditor(column, options, appData, setAppData, widgetKey) }
                )
                }
            />
        )
    });
};

export default TableColumnElement;